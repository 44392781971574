import React from "react";
// import Layout from "./components/Layout";
// import Header from "./components/Header";
// import FlexiGrid from "./components/FlexiGrid";
// import RecentlyAdded from "./components/RecentlyAdded";
// import * as firebase from "firebase"
// import firebaseConfig from "./firebase.config"
import styled from "styled-components";
// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig)
// }

// function App() {
//   return <FlexiGrid></FlexiGrid>;
// }

function App() {
  return <Wrapper>the multitube site is currently disabled. sorry!</Wrapper>;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
  font-size: 20px;
`;

// function App() {
//   return (
//     <Layout>
//       <Header />
//       <RecentlyAdded></RecentlyAdded>
//     </Layout>
//   )
// }

export default App;
